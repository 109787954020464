import { Prices } from './types';

const prices: Prices = {
  first_appointment_online: {
    DE: '75 €',
    CH: 'CHF 129',
  },
  first_appointment_clinic: {
    DE: '109 €',
    CH: 'CHF 129',
  },
  follow_up_online: {
    DE: '39 €',
    CH: 'CHF 99',
  },
  no_show_fee: {
    DE: '19 €',
    CH: 'CHF 49',
  },
};

export default prices;
