import { useMemo } from 'react';
import type { CountryCode } from '../localizations/types';

export type Localization = {
  country_code: CountryCode;
};

export default function useLocalization(): Localization {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  return useMemo((): Localization => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      country_code: LOCALIZATION,
    };
  }, [LOCALIZATION]);
}
