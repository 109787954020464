import { ImagesLocalization } from './types';
import logo from '../assets/images/logo.png';
import logo_white from '../assets/images/logo_white.png';
import flag_ch from '../assets/images/flags/CH.svg';
import flag_de from '../assets/images/flags/DE.svg';

const images: ImagesLocalization = {
  logo: {
    DE: logo,
    CH: logo,
  },
  logo_white: {
    DE: logo_white,
    CH: logo_white,
  },
  flag: {
    DE: flag_de,
    CH: flag_ch,
  }
};

export default images;
