import { useQuery } from "@tanstack/react-query";
import http from "components/util/http";

type Response = {
  results: Questionnaire[];
}

type Questionnaire =  {
  additional_notes: string;
  cannabis_treatment: boolean;
  cannabis_treatment_details: string;
  cannabis_treatment_document: string | null;
  confirmed: boolean;
  created_at: string;
  existing_conditions: string[];
  id: number;
  patient: number;
  previous_treatment: boolean;
  previous_treatment_details: string;
  previous_treatment_document: string | null;
  symptom_severity: number;
  symptoms: string[];
  symptoms_description: string;
  symptoms_diagnosis_document: string | null;
  updated_at: string;
};

export default function useQuestionnaire(): Questionnaire | undefined {
  const response = useQuery<Response, Error>({
    queryKey: ['questionaire-result'],
    queryFn: async () => await http.get('/medical/patient-survey/'),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  return response.data?.results[0];
}