import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikErrors } from 'formik';
import { string } from 'yup';
import Title from 'components/util/Title';
import Input from 'styles/FormikInput';
import Button from 'styles/Button';
import { useAuthContext } from 'components/util/useAuth';
import { useNotificationContext } from 'components/Notification';
import Layout from './Layout';
import { useEffect, useState } from 'react';
import useLocalizedServicePage from '../../hooks/useLocalizedServicePage';
import useLocalizedMarketingPageLinks from '../../hooks/useLocalizedMarketingPageLinks';

interface LoginFormValues {
  email: string;
  password: string;
}

interface MyFormProps {
  initialEmail?: string;
  setEmail: (mail: string) => void;
}

interface EmailConfirmationStatusTextProps {
  showSuccessfulEmailConfirmation: boolean;
}

const LoginForm = ({ initialEmail, setEmail }: MyFormProps) => {
  const auth = useAuthContext();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { clearNotification, showNotification } = useNotificationContext();

  return (
    <Formik
      initialValues={{
        email: initialEmail || '',
        password: '',
      }}
      validate={async (values: LoginFormValues) => {
        let errors: FormikErrors<LoginFormValues> = {};

        if (!values.email) {
          errors.email = 'Bitte geben Sie ihre E-Mail-Adresse ein';
        } else if (values.email) {
          await string()
            .email()
            .validate(values.email)
            .catch(() => {
              errors.email = 'Ungültige E-Mail-Adresse';
            });
        }

        if (!values.password) {
          errors.password = 'Bitte geben Sie Ihr Passwort ein';
        }

        setEmail(values.email);
        return errors;
      }}
      onSubmit={async (values, { setErrors }) => {
        clearNotification();
        auth.login(values.email.toLowerCase(), values.password).then(
          () => {
            window.dataLayer.push({
              event: 'loginpage-submit',
              eventStatus: 'success',
            });
            navigate(location?.state?.from?.pathname || '/therapie');
          },
          () => {
            window.dataLayer.push({
              event: 'loginpage-submit',
              eventStatus: 'error',
            });
            setErrors({ email: ' ' });
            showNotification({
              type: 'error',
              text: 'Diese Kombination von Benutzername und Passwort ist uns leider nicht bekannt oder das Konto wurde noch nicht aktiviert. Bitte versuchen Sie es erneut.',
            });
          },
        );
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="E-Mail-Adresse"
            error={!!(formikProps.touched.email && formikProps.errors.email)}
            errorMessage={formikProps.errors.email}
            className="mb-2"
          />
          <Input
            id="password"
            type="password"
            name="password"
            placeholder="Passwort"
            error={!!(formikProps.touched.password && formikProps.errors.password)}
            errorMessage={formikProps.errors.password}
            className="mb-4"
          />
          <Button type="submit" disabled={formikProps.isSubmitting}>
            ANMELDEN
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const EmailConfirmationStatusText= ({ showSuccessfulEmailConfirmation }: EmailConfirmationStatusTextProps) => {
  const { contact_email } = useLocalizedServicePage();
  const { marketing_page, marketing_page_simple_address } = useLocalizedMarketingPageLinks();

  return (showSuccessfulEmailConfirmation) ? (
    <div className="mb-6">
      <h2 className="mb-2">Dein Konto wurde erfolgreich aktiviert!</h2>
      <p className="mb-2">Ab sofort kannst Du Dich mit Deiner E-Mail-Adresse und Deinem Passwort in Deinem persönlichen Bereich auf <a
        className="text-swopa-primary-dark-blue cursor-pointer hover:text-swopa-accent-green"
        href={marketing_page}>
          {marketing_page_simple_address}
      </a> einloggen.</p>
      <p className="mb-2">Vielen Dank, dass Du Dich für Enmedify entschieden hast. Wir freuen uns, Dich auf Deinem Weg zu begleiten!</p>
      <p>Bei Fragen stehen wir Dir gerne zur Verfügung: <a className="text-swopa-primary-dark-blue cursor-pointer hover:text-swopa-accent-green" href={`mailto:${contact_email}`}>{contact_email}</a></p>
    </div>
  ) : (
    <div className="mb-6">
    <h2 className="mb-2">Aktivierung fehlgeschlagen</h2>
      <p className="mb-2">Leider konnte Ihr Konto nicht aktiviert werden. Bitte versuchen Sie es erneut oder senden Sie
        eine neue Aktivierungsmail.</p>
      <p className="mb-2">Um eine neue Aktivierungsmail zu senden, verwenden Sie bitte die Option <a
        className="text-swopa-primary-dark-blue cursor-pointer hover:text-swopa-accent-green"
        href="/aktivierung-erneut-senden"
      >
        Aktivierungsmail erneut senden
      </a>.</p>
      <p>Vielen Dank für Ihr Verständnis.</p>
    </div>
  );
};

export default function Login() {
  const [mail, setMail] = useState('');

  const [ searchParams ] = useSearchParams();
  const emailConfirmation = searchParams.get('emailConfirmation')
  const showSuccessfulEmailConfirmation = emailConfirmation === 'confirmed'

  useEffect(() => {
    if (emailConfirmation) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  return (
    <Layout>
      <Title title="Login" />
      { emailConfirmation ? <EmailConfirmationStatusText showSuccessfulEmailConfirmation={showSuccessfulEmailConfirmation} />: <></> }
      <h2 className="mb-8">Anmelden</h2>
      <LoginForm setEmail={setMail} />
      <div className="flex justify-between mt-2">
        <div className="flex flex-col space-y-1">
          <a
            className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green"
            href={`/passwort-vergessen?email=${mail}`}
          >
            Passwort vergessen?
          </a>
          <a
            className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green"
            href="/aktivierung-erneut-senden"
          >
            Aktivierungsmail erneut senden
          </a>
        </div>
        <a
          className="text-swopa-primary-dark-blue cursor-pointer hover:text-swopa-accent-green"
          href="/register/personal"
          data-clickelement="loginpage-register"
        >
          Konto erstellen
        </a>
      </div>
    </Layout>
  );
}
