import { MarketingPageLinksLocalization } from './types';

const marketingPageLinks: MarketingPageLinksLocalization = {
  impressum: {
    DE: '/impressum',
    CH: '/impressum',
  },
  privacy_policy: {
    DE: '/datenschutz',
    CH: '/datenschutz',
  },
  general_terms: {
    DE: '/agb',
    CH: '/agb',
  },
  info_sheet: {
    DE: '/aufklarungsbogen',
    CH: '/aufklarungsbogen',
  }
};

export default marketingPageLinks;
