import ReactDOM from 'react-dom';

interface IModalProps {
  onClose: () => void;
}

export default function Modal({ onClose, children }: React.PropsWithChildren<IModalProps>) {
  const portalDiv = document.getElementById('portal') || document.createElement('div');

  return ReactDOM.createPortal(
    <div
      className="fixed top-0 left-0 h-full w-full z-50 filter backdrop-blur-md bg-white/50 overflow-y-scroll flex justify-center items-center safari-scroll"
      onClick={onClose}
    >
      <div className="max-h-full" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    portalDiv,
  );
}
