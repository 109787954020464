import Sidebar from 'components/Onboarding/Sidebar';
import Header from 'components/Navigation/Header';
import { RequireAuth } from 'components/RequireAuth';
import React, { useEffect, useState } from 'react';
import AppointmentBookingConfirmFooter from '../AppointmentBookingConfirmationFooter';

interface SignupLayoutProps {
  children: React.ReactNode;
}

export default function AppointmentBookingLayout({ children }: SignupLayoutProps) {
  const [iframeHeightSize, setIframeHeightSize] = useState('h-[calc(100vh-160px)] sm:h-[calc(100vh-80px)] xl:h-[calc(100vh-129px)]');
  const [displayBookingConfirmFooter, setDisplayBookingConfirmFooter] = useState(false);
  const classNames = 'max-w-[865px] xxl:max-w-[1115px] m-auto relative';
  const eterminOriginAddress = process.env.REACT_APP_ETERMIN_ORIGIN_ADDRESS as string

  const enableFooter = (event: MessageEvent<any>) => {
    if (event.origin === eterminOriginAddress && event.data.includes('AppointmentBooked')) {
      setDisplayBookingConfirmFooter(true);
      setIframeHeightSize('h-[calc(100vh-224px)] sm:h-[calc(100vh-144px)] xl:h-[calc(100vh-193px)]');
      window.removeEventListener('message', enableFooter);
    }
  };

  useEffect(() => {
    window.addEventListener('message', enableFooter);

    return () => { window.removeEventListener('message', enableFooter); };
  }, []);

  return (
    <RequireAuth>
      <div className="w-screen flex flex-col lg:flex-row overflow-x-hidden mt-20 xl:mt-0 bg-swopa-hover-grey ">
        <div className="lg:w-[70%] xxl:w-[80%] lg:overflow-y-auto">
          <Header isOnboarding />
          <div
            id="iframe-wrapper"
            className={classNames + ' ' + iframeHeightSize}
          >
            {children}
          </div>
          {displayBookingConfirmFooter ? <AppointmentBookingConfirmFooter /> : <></>}
        </div>
        <Sidebar />
      </div>
    </RequireAuth>
  );
}
