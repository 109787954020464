import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import ChangeEmailForm from 'components/Settings/ChangeEmailForm';
import ChangePasswordForm from 'components/Settings/ChangePasswordForm';
import { ReactComponent as CloseCrossIcon } from 'assets/icons/close_cross_circled.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_circled.svg';
import Box from 'styles/Box';
import Title from 'components/util/Title';
import Button from 'styles/Button';
import { useNotificationContext } from 'components/Notification';
import GeneralDataForm from 'components/Settings/GeneralDataForm';
import HealthDataForm from 'components/Settings/HealthDataForm';
import usePatient from 'components/Navigation/usePatient';

/**
 * Section for settings forms with content on the left and information about said content on the right side
 * @param children content displayed on the left (mostly forms)
 * @param info content displayed on the right for information
 * @param title title displayed on top of the section
 * @param className styling for the entire section
 * @returns ReactElement
 */
function ContentSection({
  children,
  className,
  title,
  info,
}: React.PropsWithChildren<{ className?: string; title: string; info?: React.ReactNode }>) {
  const [showInfo, setShowInfo] = useState(false);

  let infoIcon = useMemo(() => {
    if (!info) {
      return null;
    }

    if (showInfo) {
      return (
        <CloseCrossIcon
          onClick={() => setShowInfo(false)}
          className="cursor-pointer md:hidden text-swopa-secondary-grey"
        />
      );
    }

    return (
      <InfoIcon
        onClick={() => setShowInfo(true)}
        className="cursor-pointer md:hidden text-swopa-secondary-grey"
      />
    );
  }, [info, showInfo]);

  return (
    <>
      <div className={classnames(className, 'flex flex-row justify-between items-center mb-2')}>
        <h2>{title}</h2>
        {infoIcon}
      </div>
      <div className="grid md:grid-cols-2 md:gap-x-6">
        <Box className="order-last md:order-first">
          <div className="p-8">{children}</div>
        </Box>
        <div
          className={classnames(
            {
              hidden: !showInfo,
            },
            'order-first md:order-last md:flex text-swopa-primary-dark-blue mb-2 md:mb-0 pb-2 md:pl-8',
          )}
        >
          {info}
        </div>
      </div>
    </>
  );
}

/**
 * Page representing the Settings section of the user
 * @returns ReactElement
 */
export default function SettingsIndexPage() {
  const { clearNotification } = useNotificationContext();
  const navigate = useNavigate();
  const { data: patient } = usePatient();

  useEffect(() => {
    return () => clearNotification();
  }, [clearNotification]);

  if (!patient) {
    return <></>;
  }

  return (
    <>
      <Title title="Einstellungen" />
      <ContentSection
        info={
          !patient.street ? (
            <div className="p-4 flex rounded border border-swopa-secondary-light-blue bg-[#E4ECF7] space-x-[10px] h-fit">
              <InfoIcon className="text-swopa-secondary-light-blue shrink-0 w-4 h-4 mt-[2px]" />
              <div className="grid gap-1 text-swopa-secondary-light-blue">
                <h2 className="text-swopa-secondary-light-blue">Jetzt ausfüllen</h2>
                <span>
                  Um einen schnellen und reibungslosen Ersttermin zu gewährleisten, möchten wir Sie
                  bitten, Ihre <b>allgemeinen</b> und <b>gesundheitsbezogenen</b> Angaben
                  auszufüllen.
                </span>
              </div>
            </div>
          ) : undefined
        }
        title="Allgemeine Angaben"
      >
        <GeneralDataForm isBookingAppointment={false} />
      </ContentSection>
      <ContentSection className="mt-12" title="Gesundheitsbezogene Angaben">
        <HealthDataForm />
      </ContentSection>
      <ContentSection
        className="mt-12"
        title={'E-Mail-Adresse ändern'}
        info={
          <ul className="list-decimal pl-[24px]">
            <li>Wir schicken Ihnen einen Verifizierungs-Link an Ihre neue E-Mail-Adresse.</li>
            <li>
              Klicken Sie auf den Link und bestätigen Sie somit, dass Sie der Besitzer der neuen
              E-Mail-Adresse sind.
            </li>
          </ul>
        }
      >
        <ChangeEmailForm />
      </ContentSection>
      <ContentSection
        className="mt-12"
        title={'Passwort ändern'}
        info={<>Ändern Sie zur Sicherheit in regelmäßigen Abständen Ihr Passwort.</>}
      >
        <ChangePasswordForm />
      </ContentSection>
      <ContentSection className="mt-12" title={'Konto'}>
        <Button onClick={() => navigate('./konto-loeschen')} bgColor={'grey'}>
          KONTO LÖSCHEN
        </Button>
      </ContentSection>
    </>
  );
}
