import usePatient from 'components/Navigation/usePatient';
import { useNotificationContext } from 'components/Notification';
import http from 'components/util/http';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Button from 'styles/Button';
import Input from 'styles/FormikInput';
import Toggle from 'styles/Toggle';
import useLocalizedMarketingPageLinks from 'hooks/useLocalizedMarketingPageLinks';
import useLocalizedServicePage from 'hooks/useLocalizedServicePage';
import * as Yup from 'yup';
import useLocalization from '../../hooks/useLocalization';

interface GeneralDataFormValues {
  firstName: string;
  lastName: string;
  street: string;
  postal: string;
  city: string;
  phone: string;
  ahv?: string;
  deliveryFirstName?: string;
  deliveryLastName?: string;
  deliveryStreet?: string;
  deliveryPostal?: string;
  deliveryCity?: string;
  infoSheetAccepted?: boolean;
  showDeliveryAddress?: boolean;
  isBookingAppointment?: boolean;
}

interface IGeneralDataForm {
  buttonLabel?: string;
  isBookingAppointment?: boolean;
  appointmentUrl?: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Bitte geben Sie Ihren Namen ein'),
  lastName: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
  street: Yup.string().required('Bitte geben Sie Ihre Straße und Hausnummer ein'),
  postal: Yup.string().required('Bitte geben Sie Ihre Postleitzahl an'),
  city: Yup.string().required('Bitte geben Sie Ihre Stadt an'),
  phone: Yup.number()
    .required('Bitte geben Sie die Telefonnummer an')
    .typeError('Bitte geben Sie die Telefonnummer an'),
  ahv: Yup.string()
    .matches(/^(756(\.\d{4}\.\d{4}\.\d{2}|(\d{10})))$/, 'Bitte geben Sie Ihre AVH-Nummer an')
    .optional(),
  deliveryFirstName: Yup.string().when('showDeliveryAddress', {
    is: true,
    then: Yup.string().required('Bitte geben Sie Namen ein'),
    otherwise: Yup.string().notRequired(),
  }),
  deliveryLastName: Yup.string().when('showDeliveryAddress', {
    is: true,
    then: Yup.string().required('Bitte geben Sie Nachnamen ein'),
    otherwise: Yup.string().notRequired(),
  }),
  deliveryStreet: Yup.string().when('showDeliveryAddress', {
    is: true,
    then: Yup.string().required('Bitte geben Sie Straße ein'),
    otherwise: Yup.string().notRequired(),
  }),
  deliveryPostal: Yup.string().when('showDeliveryAddress', {
    is: true,
    then: Yup.string().required('Bitte geben Sie Postleitzahl an'),
    otherwise: Yup.string().notRequired(),
  }),
  deliveryCity: Yup.string().when('showDeliveryAddress', {
    is: true,
    then: Yup.string().required('Bitte geben Sie Stadt an'),
    otherwise: Yup.string().notRequired(),
  }),
  infoSheetAccepted: Yup.boolean().when('isBookingAppointment', {
    is: true,
    then: Yup.boolean().oneOf([true], 'Bitte akzeptieren Sie das Infoblatt'),
    otherwise: Yup.boolean().notRequired(),
  }),
});

export default function GeneralDataForm({
  buttonLabel,
  isBookingAppointment,
  appointmentUrl,
}: IGeneralDataForm) {
  const { data: patient } = usePatient();
  const queryClient = useQueryClient();
  const { showNotification } = useNotificationContext();
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const navigate = useNavigate();
  const { info_sheet } = useLocalizedMarketingPageLinks();
  const { telephone_country_code } = useLocalizedServicePage();
  const { country_code } = useLocalization();

  const phonePlaceholder = `${telephone_country_code} 0123456789`;

  if (!patient) {
    return <></>;
  }

  const handleSubmit = async (
    formData: GeneralDataFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    let data: any = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      street: formData.street,
      postal_code: formData.postal,
      city: formData.city,
      phone_number: formData.phone,
      ahv: formData.ahv,
      billing_city: formData.deliveryCity,
      billing_first_name: formData.deliveryFirstName,
      billing_last_name: formData.deliveryLastName,
      billing_postal_code: formData.deliveryPostal,
      billing_street: formData.deliveryStreet,
      billing_address_is_different: showDeliveryAddress,
    };

    const updatePatientPromise = http.patch(`/medical/patients/me/`, data);
    const updateInfosheetAcceptancePromise = isBookingAppointment
      ? http.patch('/medical/patients/me/', {
          is_info_sheet_accepted: formData.infoSheetAccepted,
        })
      : Promise.resolve();

    Promise.all([updatePatientPromise, updateInfosheetAcceptancePromise])
      .then(() => {
        showNotification(
          {
            type: 'success',
            text: 'Daten erfolgreich gespeichert',
          },
          3000,
        );
        queryClient.invalidateQueries({
          queryKey: ['patient'],
        });

        if (isBookingAppointment && appointmentUrl) {
          navigate(`/termin`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik<GeneralDataFormValues>
      initialValues={{
        firstName: patient.first_name || '',
        lastName: patient.last_name || '',
        street: patient.street || '',
        postal: patient.postal_code || '',
        city: patient.city || '',
        phone: patient.phone_number || '',
        ahv: patient.ahv || '',
        deliveryFirstName: patient.billing_first_name,
        deliveryLastName: patient.billing_last_name,
        deliveryCity: patient.billing_city,
        deliveryPostal: patient.billing_postal_code,
        deliveryStreet: patient.billing_street,
        infoSheetAccepted: false,
        //only for controlling Yup validation of optional fields
        showDeliveryAddress,
        isBookingAppointment,
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <h3 className="mb-2">Name</h3>
          <div className="grid mb-6 grid-cols-2 gap-2">
            <Input
              error={!!(formikProps.touched.firstName && formikProps.errors.firstName)}
              errorMessage={formikProps.errors.firstName}
              id="firstName"
              name="firstName"
              placeholder="Vorname"
            />
            <Input
              error={!!(formikProps.touched.lastName && formikProps.errors.lastName)}
              errorMessage={formikProps.errors.lastName}
              id="lastName"
              name="lastName"
              placeholder="Nachname"
            />
          </div>
          <h3 className="mb-2">Anschrift/Lieferadresse</h3>
          <Input
            error={!!(formikProps.touched.street && formikProps.errors.street)}
            errorMessage={formikProps.errors.street}
            id="street"
            name="street"
            placeholder="Straße und Hausnummer"
            className="mb-2"
          />
          <div className="grid mb-4 grid-cols-2 gap-2">
            <Input
              error={!!(formikProps.touched.postal && formikProps.errors.postal)}
              errorMessage={formikProps.errors.postal}
              id="postal"
              name="postal"
              placeholder="PLZ"
            />
            <Input
              error={!!(formikProps.touched.city && formikProps.errors.city)}
              errorMessage={formikProps.errors.city}
              id="city"
              name="city"
              placeholder="Stadt"
            />
          </div>
          <div className="flex items-center space-x-2 mb-6">
            <Toggle
              name="billing_address_is_different"
              startState={patient.billing_address_is_different}
              onChange={(val) => {
                if (!val) {
                  formikProps.values.deliveryFirstName = '';
                  formikProps.values.deliveryLastName = '';
                  formikProps.values.deliveryPostal = '';
                  formikProps.values.deliveryCity = '';
                  formikProps.values.deliveryStreet = '';
                }
                setShowDeliveryAddress(val);
              }}
            />
            <span className="text-swopa-secondary-grey text-h3">Rechnungsadresse abweichend</span>
          </div>
          {showDeliveryAddress && (
            <>
              <h3 className="mb-2">Rechnungsadresse</h3>
              <div className="grid mb-2 grid-cols-2 gap-2">
                <Input
                  error={!!formikProps.errors.deliveryFirstName}
                  errorMessage={formikProps.errors.deliveryFirstName}
                  id="deliveryFirstName"
                  name="deliveryFirstName"
                  placeholder="Vorname"
                />
                <Input
                  error={!!formikProps.errors.deliveryLastName}
                  errorMessage={formikProps.errors.deliveryLastName}
                  id="deliveryLastName"
                  name="deliveryLastName"
                  placeholder="Nachname"
                />
              </div>
              <Input
                error={!!formikProps.errors.deliveryStreet}
                errorMessage={formikProps.errors.deliveryStreet}
                id="deliveryStreet"
                name="deliveryStreet"
                placeholder="Straße und Hausnummer"
                className="mb-2"
              />
              <div className="grid mb-6 grid-cols-2 gap-2">
                <Input
                  error={!!formikProps.errors.deliveryPostal}
                  errorMessage={formikProps.errors.deliveryPostal}
                  id="deliveryPostal"
                  name="deliveryPostal"
                  placeholder="PLZ"
                />
                <Input
                  error={!!formikProps.errors.deliveryCity}
                  errorMessage={formikProps.errors.deliveryCity}
                  id="deliveryCity"
                  name="deliveryCity"
                  placeholder="Stadt"
                />
              </div>
            </>
          )}
          <h3 className="mb-2">Telefonnummer</h3>
          <Input
            error={!!(formikProps.touched.phone && formikProps.errors.phone)}
            errorMessage={formikProps.errors.phone}
            id="phone"
            name="phone"
            placeholder={phonePlaceholder}
            className="mb-2"
          />
          <span className="text-swopa-secondary-grey text-h3">
            Die Angabe Ihrer Telefonnummer ist wichtig, um Sie in besonderen Fällen (z.B. bei
            Terminausfall) erreichen zu können.
          </span>
          {country_code === 'CH' && (
            <>
              <h3 className="mb-2 mt-6">AHV</h3>
              <Input
                id="ahv"
                name="ahv"
                placeholder="AHV"
                className="mb-2"
                error={!!(formikProps.touched.ahv && formikProps.errors.ahv)}
                errorMessage={formikProps.errors.ahv}
              />
            </>
          )}
          {isBookingAppointment && (
            <div>
              <br />
              <h3 className="mb-2">Aufklärungsbogen</h3>
              <label
                style={{
                  color: formikProps.errors.infoSheetAccepted ? 'red' : 'inherit',
                }}
              >
                <Field
                  type="checkbox"
                  id="infoSheetAccepted"
                  name="infoSheetAccepted"
                  checked={formikProps.values.infoSheetAccepted}
                  onChange={formikProps.handleChange}
                  error={formikProps.errors.infoSheetAccepted}
                />
                &nbsp;Ich bestätige, dass alle meine Angaben wahrheitsgemäß sind. Ich nehme
                den&nbsp;
                <a target="_blank" href={info_sheet} className="text-swopa-accent-green underline">
                  Aufklärungsbogen
                </a>
                &nbsp;zur Kenntnis und bin damit einverstanden.
              </label>
            </div>
          )}
          <div className="mt-6">
            <Button type="submit" disabled={formikProps.isSubmitting}>
              {buttonLabel || 'Speichern'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
