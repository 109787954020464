import * as Yup from 'yup';
import http from 'components/util/http';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import FormikSelect from 'styles/FormikSelect';
import FormikCheckbox from 'styles/Signup/FormikCheckbox';
import Button from 'styles/Button';
import { Patient } from 'components/Navigation/usePatient';

type PregnancyValues = {
  isPregnant?: boolean;
  isBreastfeeding?: boolean;
  hasAnsweredTruthfully: boolean;
};

const pregnancyValidationSchema = Yup.object().shape({
  isPregnant: Yup.boolean().required('Bitte wählen Sie eine Option aus'),
  isBreastfeeding: Yup.boolean().required('Bitte wählen Sie eine Option aus'),
  hasAnsweredTruthfully: Yup.boolean().isTrue().required(),
});

type PregnancyQuestionProps = {
  onContinue: (val: Patient) => void;
  className: string;
  autocompleteWithNoAnswers: boolean;
};

const YES_NO_OPTIONS = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

export default function PregnancyQuestions({
  onContinue,
  className,
  autocompleteWithNoAnswers,
}: PregnancyQuestionProps) {
  const submit = useMutation({
    mutationFn: (values: PregnancyValues) =>
      http.patch('/medical/patients/me/', {
        is_pregnant: values.isPregnant,
        is_breastfeeding: values.isBreastfeeding,
      }),
    onSuccess: (val: Patient) => onContinue(val),
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <Formik<PregnancyValues>
      initialValues={{
        isPregnant: autocompleteWithNoAnswers ? false : undefined,
        isBreastfeeding: autocompleteWithNoAnswers ? false : undefined,
        hasAnsweredTruthfully: false,
      }}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={(val) => submit.mutate(val)}
    >
      {({ values, errors, handleSubmit, validateForm, setErrors }) => (
        <Form onSubmit={handleSubmit} className={className}>
          <h2 className="md:text-2xl text-swopa-primary-dark-blue font-bold mb-2 max-w-sm text-center mx-auto">
            Fragebogen
          </h2>
          <h3 className="max-w-xl md:text-lg text-swopa-secondary-grey text-center mb-5">
            Um zu prüfen, ob Sie für eine Behandlung mit medizinischem Cannabis in Frage kommen
            bestätigen Sie bitte, dass Sie nicht schwanger sind oder stillen:
          </h3>
          <div className="w-64 space-y-5 mx-auto">
            <FormikSelect name="isPregnant" label="Sind Sie schwanger?" options={YES_NO_OPTIONS} />
            <FormikSelect name="isBreastfeeding" label="Stillen Sie?" options={YES_NO_OPTIONS} />
            <FormikCheckbox
              name="hasAnsweredTruthfully"
              id="hasAnsweredTruthfully"
              label="Ich bestätige alle Angaben nach bestem Wissen und Gewissen gemacht zu
haben."
              type="checkbox"
              error={!!errors.hasAnsweredTruthfully}
            />
            <Button
              type="button"
              disabled={!values.hasAnsweredTruthfully}
              onClick={() => {
                const isValid = pregnancyValidationSchema.isValidSync(values);
                if (!isValid) {
                  validateForm();
                } else {
                  setErrors({});
                  handleSubmit();
                }
              }}
            >
              Weiter
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
