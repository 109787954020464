import { useMemo } from 'react';
import type { CountryCode } from '../localizations/types';
import servicePage from '../localizations/servicePage';

export type LocalizedServicePage = {
  telephone_contact: string;
  contact_email: string;
  address: string;
  telephone_country_code: string;
};

export default function useLocalizedServicePage(): LocalizedServicePage {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  return useMemo((): LocalizedServicePage => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      telephone_contact: servicePage.telephone_contact[LOCALIZATION],
      contact_email: servicePage.contact_email[LOCALIZATION],
      address: servicePage.address[LOCALIZATION],
      telephone_country_code: servicePage.telephone_country_code[LOCALIZATION]
    };
  }, [LOCALIZATION]);
}
