import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import http from 'components/util/http';

export default function EmailReset() {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.has('uid') || !params.has('token')) {
      navigate(`/login?emailConfirmation=invalid`);
      return;
    }

    http
      .post('/auth/users/email-confirm/', { uid: params.get('uid'), token: params.get('token') })
      .then(() => {
        navigate('/login?emailConfirmation=confirmed');
      })
      .catch(() => {
        navigate('/login?emailConfirmation=invalid');
      });
  }, []);

  return null;
}
